"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.loop = void 0;
const constants_1 = require("./utils/constants");
const wait_1 = require("./utils/wait");
const activeTasks_1 = require("./activeTasks");
let loopIsActive = false;
const checkDate = (startTime) => Date.now() - startTime < constants_1.TASKS_LIMIT;
const loop = function loop() {
    return __awaiter(this, void 0, void 0, function* () {
        if (loopIsActive) {
            return;
        }
        loopIsActive = true;
        while (true) {
            if (activeTasks_1.activeTasks.size === 0) {
                loopIsActive = false;
                return;
            }
            let taskStartTime = Date.now();
            tasksLoop: while (checkDate(taskStartTime)) {
                for (const task of activeTasks_1.activeTasks) {
                    const onTaskEnd = () => {
                        activeTasks_1.activeTasks.delete(task);
                        if (task.logger) {
                            task.logger.end();
                        }
                    };
                    if (!checkDate(taskStartTime)) {
                        break tasksLoop;
                    }
                    if (task.aborted) {
                        onTaskEnd();
                        continue;
                    }
                    try {
                        task.result = task.generator.next();
                    }
                    catch (e) {
                        onTaskEnd();
                        task.reject(e);
                        continue;
                    }
                    if (task.result.done) {
                        onTaskEnd();
                        task.resolve(task.result.value);
                        continue;
                    }
                    if (task.logger) {
                        task.logger.tick();
                    }
                }
            }
            yield wait_1.wait(constants_1.TASKS_PAUSE);
        }
    });
};
exports.loop = loop;
