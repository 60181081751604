"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.statLogger = void 0;
const constants_1 = require("./constants");
const statLogger = (name) => {
    let ticks = [];
    let lastTickTime = performance.now();
    return {
        tick: () => {
            ticks.push(performance.now() - lastTickTime);
            lastTickTime = performance.now();
        },
        start: () => {
            console.log(`${name} started`);
        },
        end: () => {
            const sum = ticks.reduce((a, b) => a + b, 0);
            const avg = sum / ticks.length;
            console.log(`${name}\nTicks ${ticks.length}\nAverage time is ${avg.toFixed(2)}ms/tick.`);
            if (avg < constants_1.TASKS_LIMIT / 200) {
                console.warn("One iteration takes too little time. Try to yield less often.");
            }
            if (avg > constants_1.TASKS_LIMIT / 2) {
                console.warn("One iteration takes too long. Try to yield more often.");
            }
            console.log(`${name} finished`);
        },
    };
};
exports.statLogger = statLogger;
