"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parallelize = void 0;
const constants_1 = require("./utils/constants");
const statLogger_1 = require("./utils/statLogger");
const activeTasks_1 = require("./activeTasks");
const loop_1 = require("./loop");
const parallelize = function parallelize(generator, config = {}) {
    const configWithDefaults = Object.assign({ debug: false }, config);
    const parallelRunner = function parallelRunner(...attrs) {
        const logger = configWithDefaults.debug
            ? statLogger_1.statLogger(generator.name || "anonymous")
            : undefined;
        const taskInterface = {
            generator: generator(...attrs),
            aborted: false,
            logger,
        };
        const promise = new Promise((res, rej) => {
            taskInterface.resolve = res;
            taskInterface.reject = rej;
        });
        activeTasks_1.activeTasks.add(taskInterface);
        promise.abort = function abort(resolve = true) {
            const task = taskInterface;
            task.aborted = true;
            if (resolve) {
                task.resolve(constants_1.Aborted);
            }
            else {
                task.reject(constants_1.Aborted);
            }
        };
        loop_1.loop();
        if (configWithDefaults.maxTime) {
            const abortTimeout = setTimeout(() => {
                taskInterface.aborted = true;
            }, configWithDefaults.maxTime);
            promise.finally(() => clearTimeout(abortTimeout));
        }
        if (logger) {
            logger.start();
        }
        return promise;
    };
    return parallelRunner;
};
exports.parallelize = parallelize;
